import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import engagementIcon from 'img/ui/engagement-icon-blue.svg';
import inspectIcon from 'img/ui/inspect-icon.svg';
import rageIcon from 'img/ui/rage-icon.svg';
import { Section, Main } from './styles';

const KeyAdvantages = () => {
  const { engagement, rage, inspect } = useStaticQuery(graphql`
    query {
      engagement: file(relativePath: { eq: "fullstory-alternative/engagement-score.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inspect: file(relativePath: { eq: "hotjar-alternative/inspect.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rage: file(relativePath: { eq: "hotjar-alternative/rage.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Main>
      <h2>More key advantages...</h2>
      <Section>
        <Img
          fluid={engagement.childImageSharp.fluid}
          alt="Engagement score"
          title="Engagement score"
        />
        <div className="content-box">
          <img src={engagementIcon} alt="engagement-icon" title="Engagement score" />
          <h3>Engagement score</h3>
          <p>
            There is a way to find the most engaging sessions without browsing manually.{' '}
            <strong>Engagement score </strong>allows you to find recordings with the most activity.
            <br />
            <br />
            The rating depends on many factors, including the number of pages visited and the number
            of clicks. Engagement score will help you find the most important sessions
            automatically.
          </p>
        </div>
      </Section>
      <Section reverse>
        <Img fluid={inspect.childImageSharp.fluid} alt="inspect mode" title="Inspect mode" />
        <div className="content-box">
          <img src={inspectIcon} alt="inspect-icon" title="Inspect mode" />
          <h3>Inspect mode</h3>
          <p>
            While you’re watching a recording, you can <strong>select a specific item</strong> on
            your website. This allows you to browse recordings in which the users interact with this
            element.
            <br /> <br />
            Depending on your needs, you can browse sessions according to CSS selectors or text
            values. This functionality makes it <strong>
              much easier to interpret A/B tests
            </strong>{' '}
            and see how users interact with particular features.
          </p>
        </div>
      </Section>
      <Section>
        <Img
          fluid={rage.childImageSharp.fluid}
          alt="rage click and error clicks"
          title="Rage clicks & error clicks"
        />
        <div className="content-box">
          <img src={rageIcon} alt="rage-icon" title="Rage clicks & error clicks" />
          <h3>Rage clicks & error clicks</h3>
          <p>
            Thanks to rage clicks and error clicks, you can{' '}
            <strong>spot pain points and errors</strong> on your website automatically.
            <br /> <br />
            Rage clicks are detected when the user keeps clicking or tapping repeatedly. It
            indicates that they were not able to achieve a goal. Automatic error clicks and rage
            clicks detection makes it much easier for you to{' '}
            <strong>find the most urgent issues</strong>.
          </p>
        </div>
      </Section>
    </Main>
  );
};

export default KeyAdvantages;
