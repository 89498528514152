import hotjar from 'img/ui/hotjar-favicon.png';

export const dataSet = [
  {
    name: 'Engagement score',
    tooltip: 'Find and watch the most interesting recordings.',
  },
  {
    name: 'Heatmaps',
  },
  {
    name: 'Rage clicks & error clicks',
    tooltip: 'Spot errors and points of frustration.',
  },
  {
    name: 'Custom properties',
    tooltip:
      'Extend sessions with custom data (e.g. subscription plan) and use it to filter your recordings.',
  },
  {
    name: 'User identification',
    tooltip: 'Identify your users by name or email.',
  },
  {
    name: 'Inspect mode',
    tooltip: 'See how users interact with specific elements on your website.',
  },
  {
    name: 'Segmentation',
    tooltip: 'Create custom segments to track different behavioral patterns.',
  },
  {
    name: 'Recording rules',
    tooltip: 'Choose to record sessions based on their URL, IP and other variables.',
  },
  { name: 'Form analysis' },
  {
    name: 'Tags',
    tooltip: 'Tag session recordings to find them easily later.',
  },
  {
    name: 'Always-on recordings',
    tooltip: 'Record all sessions, all the time.',
  },
  {
    name: 'Full SPA support',
    tooltip: 'Works with React, Vue, Angular and other popular frameworks.',
  },
  {
    name: 'User journey',
    tooltip: "Eaisly discover the most important actions made during the user's session.",
  },
  {
    name: 'Custom events',
    tooltip: 'Track custom actions along with custom event properties.',
  },
  {
    name: 'Segment notifications',
    tooltip: 'Daily or weekly email notifications about segment changes.',
  },
  {
    name: 'Data export',
    tooltip: 'Export users or events to CSV or JSON.',
  },
  {
    name: 'Dev Tools',
    tooltip: 'Track console logs and JavaScript errors.',
  },
  {
    name: 'Funnels',
    tooltip: 'Series of steps that lead to a conversion',
  },
];

export const icon = hotjar;

export const LIVESESSION = [
  'check',
  'check',
  'check',
  'check',
  'check',
  'check',
  'check',
  'check',
  'check',
  'check',
  'check',
  'check',
  'check',
  'check',
  'check',
  'cross planned',
  'check',
  'check',
];

export const HOTJAR = [
  'cross',
  'check',
  'cross',
  'cross',
  'cross',
  'cross',
  'cross',
  'check',
  'check',
  'check',
  'cross',
  'cross',
  'cross',
  'cross',
  'cross',
  'cross',
  'cross',
  'cross',
];
