import React from 'react';
import { Link } from 'gatsby';

export const questions = [
  {
    question: 'Is it hard to switch from Hotjar to LiveSession?',
    answer:
      'It’s really easy! You don’t need to drop all your habits. The interface is quite similar and we do our best to make sure it’s easy to use.',
  },
  {
    question: 'How can I install LiveSession on my website?',
    answer: (
      <>
        All you need to do is paste a simple JavaScript code. We’ve created a{' '}
        <Link to="/help/how-to-install-livesession-script/">step-by-step instruction</Link> to make
        it as easy as possible.
      </>
    ),
  },
  {
    question: 'Is LiveSession free?',
    answer:
      'We offer a free 14-day trial so you can see if we’re the right match for you. The pricing starts at $49 per month.',
  },
  {
    question: 'Will LiveSession slow my website down?',
    answer:
      'Absolutely not, no need to worry! The script works asynchronously and it doesn’t influence the performance of your website.',
  },
];
