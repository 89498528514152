import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Layout from 'layout';
import StartUsing from 'components/ui/startUsing';
import FAQ from 'components/FAQ';
import CompareTo from 'components/CompareTo';
import { questions } from 'data/compare/hotjar/questions';
import { LIVESESSION, HOTJAR, dataSet, icon } from 'data/compare/hotjar/table';
import Button from 'components/ui/button';
import { Header, HeadSection, Section, StyledH2, Divider } from 'components/compare/styles';
import KeyAdvantages from 'components/compare/KeyAdvantagesHotjar';
import HeadContentFullstory from 'components/compare/HeadContentFullstory';
import pricingImg from 'img/ui/pricing.svg';
import Link from 'components/ui/link';
import TrustedBy from 'components/ui/trustedBy';
import Opinion from 'components/compare/Opinion';
import CustomersRate from 'components/compare/CustomersRate';
import Banner from 'components/compare/Banner';

const HotjarCompare = () => {
  const { pricing, hero } = useStaticQuery(graphql`
    query {
      pricing: file(relativePath: { eq: "fullstory-alternative/pricing.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero: file(relativePath: { eq: "hotjar-alternative/hero.png" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      metaDescription="Try LiveSession, a better choice than Hotjar. No more empty recordings – choose in-depth analytics, advanced filtering and learn more about your users."
      metaTitle="Hotjar Alternative | Better Session Replays"
      canonical="/compare/hotjar-alternative/"
    >
      <Header>
        <div className="container">
          <div className="head-content-box">
            <h1 className="line">
              <span>LiveSession</span> is a better choice than Hotjar
            </h1>
            <p>No empty recordings. In-depth analytics, advanced segmentation and more.</p>
            <Button secondary signUp sourceID="hero">
              Sign up free
            </Button>
          </div>
          <Img
            fluid={hero.childImageSharp.fluid}
            alt="livesession vs hotjar"
            title="Compare LiveSession with Hotjar"
            objectFit="contain"
            objectPosition="center"
          />
        </div>
      </Header>
      <div className="container">
        <HeadSection>
          <HeadContentFullstory />
        </HeadSection>
        <StyledH2 className="line">
          Where <span>LiveSession</span> takes the lead over Hotjar
        </StyledH2>
        <Section>
          <Img fluid={pricing.childImageSharp.fluid} />
          <div className="content-box">
            <img src={pricingImg} alt="pricing-icon" title="Pricing" />
            <h3>Flexible and transparent pricing</h3>
            <p>
              You can get LiveSession for just <strong>$49 per month</strong>. The plan is flexible
              and you can always change your mind.
              <br />
              <br />
              What’s more, you can choose <strong>a monthly subscription</strong> instead of the
              annual one. You don’t have to commit for a whole year, and you don’t have to pay for
              12 months at once. The subscription can be tailored to your needs.
            </p>
            <div className="buttons">
              <Button secondary signUp sourceID="pricing">
                Sign up free
              </Button>
              <Link withArrow href="/pricing/">
                Read more
              </Link>
            </div>
          </div>
        </Section>
        <CompareTo
          LSbase={LIVESESSION}
          compareBase={HOTJAR}
          dataSetBase={dataSet}
          compareTo="Hotjar"
          icon={icon}
          title="LiveSession vs. Hotjar"
          desc="Here’s why LiveSession might be a better alternative."
        />
        <Banner
          withoutPrice
          title={
            <h2 className="line">
              With <span>LiveSession</span>, you decide which sessions are worth watching.
            </h2>
          }
          desc="HotJar collects random sessions within your daily sessions limit. Here at LiveSession we record them all and give you the proper tools to dig into data that really matters."
        />
        <KeyAdvantages />
        <Opinion
          opinion="We have tried out many, but LiveSession is the best session replay tool out there."
          companyID="databox"
        />
        <CustomersRate />
        <FAQ questions={questions} twoColumns />
        <Divider />
      </div>
      <TrustedBy customText="Trusted by" />
      <StartUsing />
    </Layout>
  );
};

export default HotjarCompare;
